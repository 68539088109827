<template>
  <div class="agricultural_production fl_c">
    <div class="title_common">
		<img src="@/assets/img/header/indicate.png"/>
		<span>产业盈利概况</span>
	</div>
    <div class="fl fl_r content_box">
		<span class="lt_icon"></span>
		<span class="rt_icon"></span>
		<div class="pecent_data_box">
			<div class="btn_group">
				<div v-for="(item,index) in btnMenu" :key="index"
					class="btn" :class="[btnActiveIndex == index ? 'avtive' : '']"
					@click="handleSelect(item,index)"
				>
					{{item.name}}
				</div>
			</div>
			<div class="pecent_data_item" v-for="(item,index) in pieData" :key="index">
				<div class="title">{{item.name}}</div>
				<div class="pecent" :style="{'color': `${item.color}`}">{{((item.value / totalCount) * 100).toFixed(2) + " %"}}</div>
			</div>
		</div>
		<div ref="agriculturalBar" class="ab_style fl_r fl5 al_c ju_c"></div>
    </div>
  </div>
</template>

<script>
import { getCulturalTourismStatistics,getTalentsStatistics } from "@/utils/api/index";

export default {
	name: "InvestTabel",
	props: {},
	
	data() {
		return {
			btnActiveIndex: 0, // 按钮下标
			btnMenu: [
				{ name: '已签约' },
				{ name: '计划签约' },
			],
			changePieInterval: null,
			right_bar: null,
			currentIndex: -1,
			talentsEducationStatistics: [],
			totalCount: 0,
			pieData: [
				{ value: 5, name: "25万以下",color:'#FFA500' },
				{ value: 10, name: "25万～100万",color:'#0080FF' },
				{ value: 3, name: "100万以上",color:'#00E6E0' },
			],
			option: {
				color: ["#FFA500","#0080FF","#00E6E0"],
				title : {
					show:true,//显示策略，默认值true,可选为：true（显示） | false（隐藏）
					text: '资产分析',//主标题文本，'\n'指定换行
					x: "23%",
					y: "40%",
					textAlign: 'center',
					textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
						fontFamily: 'Arial, Verdana, sans...',
						fontSize: 16,
						fontWeight: '500',
						color: '#fff',
					},
				},
				tooltip: {
					trigger: 'item',
					formatter: "{b}占比 : {d}%"   //鼠标放上去 展示内容
				},
				legend: {
					x: 'center',
					orient: 'horizontal',
					textStyle: {
					  color: "#7589B1",
					  fontSize: 14, 
					  padding: 12
					},
					bottom: '5%',
					itemGap: 30,
					itemWidth: 8,  
					itemHeight: 14, 
				},
				series: [
					{
					   name: "阴影圈",
					   type: "pie",
					   radius: ["0", "65%"],
					   center: ["24%", "45%"],
					   emphasis: {
						  scale: false,
					   },
					   tooltip: {
						  show: false,
					   },
					   itemStyle: {
						  color: "rgba(204,225,255, 0.05)"
					   },
					   zlevel: 0,
					   labelLine: {
						  show: false,
					   },
					   data: [100],
					  },
					{
						name: "额度占比",
						type: "pie",
						radius: ["45%", "57%"], // 通过设置内径与外径将饼图变为圆环饼图
						center: ['24%', '45%'],  // 设置饼状图位置，第一个百分数调水平位置，第二个百分数调垂直位置
						itemStyle: {
							borderRadius: 0, // 设置圆环的圆角弧度
						},
						zlevel: 1,
						emphasis: {
							// 设置高亮时显示标签
							label: {
								show: false,
							},
							labelLine: {
								show: false,
								lineStyle: {
									color: "#fff" // 修改引导线的颜色
								}
							},
							scale: true, // 设置高亮时放大图形
							scaleSize: 7,
						},
						label: {
							color: "#fff",
							// 设置图形标签样式
							show: false, // 未高亮时不显示标签，否则高亮时显示的标签会与静态的标签重叠
							//position: "center",
							// 设置标签展示内容,其中{d}、{b}为echarts标签内容格式器
							formatter(param) {
								// correct the percentage
								return param.name + "\n" + ' (' + param.percent + '%)';
							},
						},
						data: [
							{ value: 5, name: "25万以下" },
							{ value: 10, name: "25万～100万" },
							{ value: 3, name: "100万以上" },
						],
					},
					{
					   name: "阴影圈",
					   type: "pie",
					   radius: ["0", "35%"],
					   center: ["24%", "45%"],
					   emphasis: {
						  scale: false,
					   },
					   tooltip: {
						  show: false,
					   },
					   itemStyle: {
						  color: "rgba(204,225,255, 0.05)",
					   },
					   zlevel: 0,
					   labelLine: {
						  show: false,
					   },
					   data: [100],
					  },
				],
			},
		};
	},
	methods: {
		salesPresentation() {
			this.center_pie = this.$echarts.init(this.$refs.agriculturalBar);
			this.center_pie.setOption(this.option);
		},
		countPieData() {
			let count = 0;
			// 统计
			this.pieData.forEach(item => {
			    count = count + item.value;
			})
			this.totalCount = count;
		},
		handleSelect(item,index) {
			this.btnActiveIndex = index;
			if(index == 1) {
				this.option.series[1].data.map( item => {
					item.value += 20
				})
				this.pieData.map( item => {
					item.value += 20
				})
				
				console.log(this.option.series[1].data)
				this.$nextTick(() => {
					this.countPieData();
					this.salesPresentation();
				})
			}
			
			
		}
	},
	created() {
		this.countPieData()
		this.$nextTick(() => {
			this.salesPresentation();
		})
	},
};
</script>

<style scoped lang="scss">
.agricultural_production {
  width: 100%;
  height: 100%;
}
.title_common {
  width: 100%;
  height: 42px;
  line-height: 42px;
  display: flex;
  align-items: center;
  padding: 9px 0;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: PingFang SC-Bold, PingFang SC;
  background-image: url(../../assets/img/header/title_03.png);
  letter-spacing: 1px;
  img {
  	width: 24px;
  	height: 24px;
  	margin-right: 16px;
  	margin-top: 2px;
  }
}

.content_box {
	background-image: linear-gradient(0deg, rgba(20,26,38,0.80) 0%, rgba(32,45,70,0.29) 100%);
	box-shadow: inset 0px 0px 30px 0px rgba(45,93,117,0.2);
	margin-top: 12px;
	position: relative;
}

.item-contain {
  width: 95%;
  height: 260px;
  margin: 10px 20px;
}
.ab_style {
  width: 100%;
  height: 200px;
  //flex: 0.8;
}
.fb_style {
  width: 100px;
  height: 250px;
}
.fl5 {
  flex: 2;
}
.zindex1 {
  z-index: 1;
  color: #ffffff;
}
.img {
  width: 40px;
  // height: 40px;
}
.countfont {
  color: rgb(0, 141, 236);
  font-size: 14px;
}
.countnum {
  font-size: 22px;
}
.lt_icon {
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/header/bevel_01.png);
  width: 10px;
  height: 10px;
}
.rt_icon::after {
  content:'';
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/header/bevel_01.png);
  width: 10px;
  height: 10px;
  transform: scaleX(-1);
  position: absolute;
  right: 0;
  top: 0;
}
.pecent_data_box {
	color: #fff;
	position: absolute;
	font-size: 14px;
	right: 7%;
	bottom: 25%;
	z-index: 222;
	.btn_group {
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 14px;
		.btn{
			padding: 3px 20px;
			border: 1px solid rgba(56,119,242,1);
			border-radius: 12px;
			cursor: pointer;
		}
		.avtive {
			background-image: linear-gradient(269deg, #5093EF 0%, #0080FF 86%);
			border-radius: 13px;
			padding: 3px 20px;
		}
	}
	.pecent_data_item {
		margin-bottom: 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.title {
			margin-right: 30px;
			letter-spacing: 1px;
		}
		.pecent {
			font-size: 18px;
		}
	}
}
</style>
