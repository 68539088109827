<template>
  <div class="farming_distribution fl_c">
    <div class="title_common">资产村规划文件概览</div>
    <div class="fl top-content zindex1 fl_c bg">
      <div class="fl fl_c ju_c font18">
        招商项目： {{ $store.state.invest.item.name }}
      </div>
      <div class="fl fl_c ju_c font18">
        占地面积： {{ $store.state.invest.item.area }}
      </div>
      <div class="fl fl_c ju_c font18">
        招商资产地址：{{ $store.state.invest.item.address }}
      </div>
      <div class="fl fl_c ju_c font18">
        资金来源及投资金额：{{ $store.state.invest.item.details }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvestCount",
  props: {
    cropData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      nowMap: ``,
    };
  },
  methods: {},
  mounted() {
    this.$nextTick((e) => {});
  },
};
</script>

<style scoped lang="scss">
.farming_distribution {
  width: 100%;
  height: 100%;
}

.title_common {
  width: 100%;
  margin: 3px;
  padding: 5px 15px 15px 70px;
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: PingFang SC-Bold, PingFang SC;
  background-image: url(../../assets/img/img5.png);
  z-index: 99999;
}
.zindex1 {
  z-index: 1;
  color: #ffffff;
}
.bg {
  background-repeat: no-repeat;
  background-size: 98% 98%;
  font-family: PingFang SC-Bold, PingFang SC;
  background-image: url(../../assets/img/farm1.png);
  background-position: center;
  padding: 15px 20px;
}
.font18 {
  font-size: 18px;
}
</style>
