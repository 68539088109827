<template>
	<div class="one_village fl_c" >
		<div class="title_common">
			<img src="@/assets/img/header/indicate.png"/>
			<span>{{ listname }}</span>
		</div>
		<div class="zindex1 fl fl_c" @click="handleClick($event)">
			<vue-seamless-scroll
				:data="orderList"
				:class-option="classOption"
				:style="{'height': `${height}px !important`}"
				class="warp"
			>
				<div
					v-for="(item,index) in orderList"
					:key="index"
					class="list-bg scrollItem"
					:data="JSON.stringify(item)"
				>
					<div class="left-box" :style="{ width: (imgType == 'people' ? '35%' : '50%')}">
						<img v-if="imgType == 'people'" src="@/assets/img/pointto_03.png"/>
						<img v-else src="@/assets/img/pointto_01.png"/>
						<span>{{ item.name ? item.name : item.name_ }}</span>
					</div>
					<div class="right-box fl fl_c ju_c">
						{{
							item.address
							? item.address
							: $route.path == `/talents`
							? item.now_work_address
							: item.in_department
						}}
					</div>
				</div>
			</vue-seamless-scroll>
		</div>
	</div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "ScrollList",
  props: {
    orderList: {
      type: Array,
      default: () => [],
    },
    listname: {
      type: String,
      default: () => `产业数据列表`,
    },
	height: {
	  type: Number,
	},
	imgType: {
		type: String,
	}
  },
  components: {
    vueSeamlessScroll,
  },
  computed: {
    classOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data() {
    return {
      listData: [],
    };
  },
  methods: {
    creatMap() {},
    handleClick(e) {
		const path = e.path || (e.composedPath && e.composedPath());
		let target = path.filter((r) => /scrollItem/.test(r.className));
		if (target.length) target = target[0];
		else return;
		const data = JSON.parse(target.getAttribute("data")); // 单项数据详情
		console.log(data)
		
		this.$store.dispatch(`map/setCenter`, data);
		switch (this.$route.path) {
			case `/culturalTourism`:
				this.$store.dispatch(`travel/setItem`, data);
				break;
			case `/partyBuilding`:
				this.$store.dispatch(`partPeople/setItem`, data);
				break;
			default:
				this.$store.dispatch(
					`${this.$route.path.substring(1)}/setItem`,
					data
				);
		  
		}
    },
  },
  mounted() {
    this.creatMap();
  },
};
</script>

<style scoped lang="scss">
.title_common {
  width: 100%;
  height: 42px;
  line-height: 42px;
  display: flex;
  align-items: center;
  padding: 9px 0;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: PingFang SC-Bold, PingFang SC;
  background-image: url(../../assets/img/header/title_03.png);
  letter-spacing: 1px;
  img {
  	width: 24px;
  	height: 24px;
  	margin-right: 16px;
  	margin-top: 2px;
  }
}
.zindex1 {
	margin-top: 12px;
}
.list-bg {
  height: 56px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  .left-box {
	cursor: pointer;
    color: #00E6E0;
    font-size: 14px;
	display: flex;
	align-items: center;
	// flex: 1;
	//width: 55%;
	height: 100%;
	background-image: linear-gradient(0deg, rgba(20,26,38,0.80) 0%, rgba(32,45,70,0.29) 100%);
	box-shadow: inset 0px 0px 30px 0px rgba(45,93,117,0.2);
	margin-right: 12px;
	padding: 0 12px;
	img {
		width: 24px;
		height: 24px;
		margin-right: 8px;
	}
  }
}
.warp {
  overflow: hidden;
  height: 504px;
}
.right-box {
	background-image: linear-gradient(0deg, rgba(20,26,38,0.80) 0%, rgba(32,45,70,0.29) 100%);
	box-shadow: inset 0px 0px 30px 0px rgba(45,93,117,0.2);
	color: white;
	font-size: 14px;
	flex: 1;
	line-height: 100%;
	height: 100%;
	text-align: center;
	letter-spacing: 1px;
}
</style>
